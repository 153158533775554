import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from 'src/components/Layout'
import PagePropTypes from 'src/utils/propTypes'
import Seo from 'src/components/Seo'
import HeadingWysiwyg from 'src/sections/HeadingWysiwyg'
import SectionPattern from 'src/components/SectionPattern'
import Section from 'src/components/Section'
import Heading2Line from 'src/components/Heading2Line'
import PostGrid from 'src/components/PostGrid'
import { Box } from '@material-ui/core/'
import { getThemeVar } from 'src/styles/mixins'

export const PostTemplate = ({ acf, featuredImage, readMoreLabel, relatedHeadings }) => {

  const { preHeading, heading, textWysiwyg } = acf.postPost;
  const related = acf.relatedArticles;

  return (
    <>
      <HeadingWysiwyg
        background={getThemeVar('color.lightblue')}
        preHeading={preHeading}
        heading={heading}
        beforeText={featuredImage && featuredImage.node && featuredImage.node.localFile && (
          <Box my={8}>
            <Img alt={featuredImage.node.altText} fluid={featuredImage.node.localFile.childImageSharp.fluid} />
          </Box>
        )}
        textWysiwyg={textWysiwyg}
      >
        <SectionPattern positionX="right" />
      </HeadingWysiwyg>

      {related && (
        <Section hasPaddingLarge paddingValuesLarge={{ xs: '48px 0  96px 0', lg: '100px 0 148px 0' }}>
          <Heading2Line preHeading={relatedHeadings.preHeading} heading={relatedHeadings.heading} />
          <PostGrid
            posts={related}
            readMoreLabel={readMoreLabel}
            cardBackground={getThemeVar('color.lightblue')}
          />
        </Section>
      )}
    </>
  )
}

PostTemplate.propTypes = {
  featuredImage: PropTypes.object, // eslint-disable-line
  acf: PropTypes.shape({
    postPost: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),
    relatedArticles: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        excerpt: PropTypes.string,
        uri: PropTypes.string.isRequired,
        featuredImage: PropTypes.object, // eslint-disable-line
      })
    ),
  }).isRequired,
  readMoreLabel: PropTypes.string,
  relatedHeadings: PropTypes.shape({
    preHeading: PropTypes.string,
    heading: PropTypes.string,
  })
}

PostTemplate.defaultProps = {
  readMoreLabel: undefined,
  relatedHeadings: {
    preHeading: "",
    heading: "",
  },
}

const Page = ({ data }) => {

    let readMore;
    let related;

    const options = data.allWp.edges[0].node.siteGeneralSettings.acfOptions;

    switch (data.wpPost.language.locale) {
      case 'en_US':
        readMore = options.readMoreEn
        related = {
          preHeading: options.relatedArticlesEn.preHeading,
          heading: options.relatedArticlesEn.heading,
        }
        break
      case 'ja':
        readMore = options.readMoreJa
        related = {
          preHeading: options.relatedArticlesJa.preHeading,
          heading: options.relatedArticlesJa.heading,
        }
        break
      default:
        readMore = options.readMoreDe
        related = {
          preHeading: options.relatedArticlesDe.preHeading,
          heading: options.relatedArticlesDe.heading,
        }
        break
    }

    const post = data.wpPost
    const { seo } = post

    let ogImage = null
    if (seo.opengraphImage) {
      ogImage = {
        alt: seo.opengraphImage.altText,
        url: seo.opengraphImage.mediaItemUrl,
        width: seo.opengraphImage.mediaDetails.width,
        height: seo.opengraphImage.mediaDetails.height,
        type: seo.opengraphImage.mimeType,
      }
    }

    let twitterImage = null
    if (seo.twitterImage) {
      twitterImage = {
        alt: seo.opengraphImage.altText,
        url: seo.opengraphImage.mediaItemUrl,
        width: seo.opengraphImage.mediaDetails.width,
        height: seo.opengraphImage.mediaDetails.height,
        type: seo.opengraphImage.mimeType,
      }
    }

  return (
    <Layout page={post} pageLocale={data.wpPost.language.locale}>

      <Seo
        title={post.title}
        locale={post.language.locale}
        description={seo.metaDesc}
        canonicalUri={post.uri}
        og={{
          title: seo.opengraphTitle,
          description: seo.opengraphDescription,
          url: seo.opengraphUrl,
          image: ogImage,
          type: seo.opengraphType,
        }}
        twitter={{
          title: seo.twitterTitle,
          description: seo.twitterDescription,
          image: twitterImage,
        }}
        article={{
          published: seo.opengraphPublishedTime,
          modified: seo.opengraphModifiedTime,
          updated: seo.opengraphModifiedTime,
        }}
      />
      <PostTemplate
        acf={data.wpPost.acfPost}
        featuredImage={data.wpPost.featuredImage}
        readMoreLabel={readMore}
        relatedHeadings={related}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostById($id: String) {
    wpPost(id: { eq: $id }) {
      ...postTranslationData
      ...post
      ...postSeo
    }
    ...readMoreLabel
    ...metadata
    ...relatedArticlesHeading
  }
`

Page.propTypes = PagePropTypes

export default Page
